<template>
  <HeaderTheHeader />
  <main class="pt-4">
    <slot />
  </main>
  <FooterTheFooter />
</template>
<script setup>

</script>

<style lang="sass">
#__nuxt
  display: flex
  flex-direction: column
  min-height: 100vh
 

main 
  flex-grow: 1
 
</style>